<template>
    <div class="documents">
        <div class="form-container">
            <h1>Соглашения</h1>
            <div :class="['documents-container']">
                <rwm-agreement-document
                        class="_row"
                        v-for="doc in agreementsDocuments"
                        :key="doc.id"
                        :date="doc.created_at"
                        :title="doc.name"
                        :file="doc.id"
                        :file-type="doc.type_id"
                        @download="download"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import RwmAgreementDocument from "../../../RWMAgreementDocument/RwmAgreementDocument";
    export default {
        name: "Agreements",
        components: {RwmAgreementDocument},
        props: {
            agreementsDocuments: Array
        },
        methods: {
            download(file) {
                this.$emit('downloadFile', file)
            }
        }
    }
</script>

<style scoped lang="scss">
    .documents {
        & .form-container {
          & .documents-container {
              display: flex;
              flex-direction: column;
              row-gap: 24px;
              & ._row {
                  position: relative;
                  &:after {
                      content: "";
                      position: absolute;
                      left: 0;
                      right: 0;
                      height: 1px;
                      opacity: 0.5;
                      background-color: $RWM-grey-light;
                      top: 0;
                      @media screen and (min-width: 1280px) {
                          top: 120%;
                      }
                  }
                  &:last-child:after {
                      content: none;
                  }
              }
          }
        }
    }
</style>