<template>
    <agreements :agreements-documents="getAGREEMENTS" @downloadFile="download" />
</template>

<script>
    import Agreements from "../../../components/pages/cabinet/documents/Agreements";
    import {mapActions, mapGetters} from "vuex"
    export default {
        name: "DocumentsAgreements",
        components: {Agreements},
        computed: {...mapGetters("DocumentsModule", ["getAGREEMENTS"])},
        data() {
            return {
                // docs: [
                //     {
                //         date: "01.11.2020",
                //         title: "Присоединяюсь к соглашению об электронном документообороте с УК РВМ Капитал",
                //         file: "sdsdf"
                //     },
                //     {
                //         date: "02.11.2020",
                //         title: "Даю согласие на обработку персональных данных",
                //         file: "sdsdf"
                //     },
                //     {
                //         date: "03.11.2020",
                //         title: "Не являюсь налоговым резидентом Соединенных Штатов Америки",
                //         file: "sdsdf"
                //     },
                //     {
                //         date: "04.11.2020",
                //         title: "Не являюсь публичным должностным лицом, супругом(ой) или близким родственником такого лица",
                //         file: "sdsdf"
                //     },
                //     {
                //         date: "05.11.2020",
                //         title: "Подтверждаю, что являюсь фактическим пользователем абонентского номера +7 111 111 11 11",
                //         file: "sdsdf"
                //     },
                //     {
                //         date: "06.11.2020",
                //         title: "Ознакомлен с правилами ДУ",
                //         file: "sdsdf"
                //     },
                // ]
            }
        },
        methods: {
            ...mapActions("DocumentsModule", ["GET_AGREEMENTS", "DOWNLOAD_DOCUMENT"]),
            download(file) {
                this.DOWNLOAD_DOCUMENT({id: file.id, name: file.name})
            }
        },
        mounted() {
            this.GET_AGREEMENTS()
        }
    }
</script>

<style scoped lang="scss">

</style>