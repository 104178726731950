<template>
    <div class="row">
        <div class="anketa">
            {{showDate}}
        </div>
        <div class="col title anketa">
            {{title}}
        </div>
        <div v-if="showDone" class="icon-document">
            <div class="icon-document__ico green">
                <v-icon :hovering="false">done</v-icon>
            </div>
        </div>
        <div class="icon-document" v-if="typeof(file) === 'string' || typeof(file) === 'number'">
            <div class="icon-document__ico">
                <v-icon v-if="fileType < 102" @click="$emit('download', {id: file, name: `${title}.pdf`})">note-blank</v-icon>
            </div>
        </div>
        <div class="icon-document" v-else-if="file && file.length > 0">
            <div class="icon-document__ico" v-for="i in file" :key="i.id">
                <v-icon @click="$emit('download', {id: i.id, name: i.origin_name})">
                    {{i.origin_name.split(".")[1].match("pdf") ? "note-blank" : "note"}}
                </v-icon>
            </div>
        </div>
        <div class="icon-document" v-else-if="!!file">
            <div class="icon-document__ico">
                <v-icon @click="$emit('download', {id: file.id, name: file.origin_name})">
                    {{file.origin_name.split(".")[1].match("pdf") ? "note-blank" : "note"}}
                </v-icon>
            </div>
        </div>
        <div class="icon-document" v-else>
            <div class="icon-document__ico">
            </div>
        </div>
    </div>
</template>

<script>
    import VIcon from "../icons/VIcon";
    import dateFormatter from "../../modules/module.date";
    export default {
        name: "RwmAgreementDocument",
        components: {VIcon},
        props: {
            date: [String, Number],
            title: String,
            file: [String, Number, Object, Array],
            fileType: [String, Number],
            showDone: {
                type: Boolean,
                default: true
            },
            showFulltime: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            showDate() {
                return dateFormatter(this.date, this.showFulltime)
            }
        }
    }
</script>

<style scoped lang="scss">
    .row {
        align-items: center;
        max-width: 100%;
        box-sizing: border-box;
        flex-wrap: nowrap;
        padding: 10px 0 0;
        @media screen and (min-width: 1280px) {
            padding: 12px 50px 12px 0;
        }
        & .anketa {
            min-width: 200px;
            text-align: center;
            @media screen and (min-width: 1280px) {
                text-align: left;
            }
        }
        & .title {
            flex: 1 1 auto;
        }
        & .icon-document {
            color: $RWM-dark;
            min-width: 100px;
            display: flex;
            &__ico {
                text-align: center;
                width: 100%;
                @media screen and (min-width: 1280px) {
                    text-align: right;
                }
            }
            & .green {
                color: $RWM-green;
            }
        }
    }
</style>